<template>
  <v-container id="whatwedo" class="ma-auto" fluid>
    <v-row class="pa-0 ma-0" height="auto">
      <v-col cols="12" class="text-center text-md-left" data-aos="slide-right">
        <span class="text-h5 text-uppercase">{{$t("whatwedo")}}</span>
      </v-col>
      <v-row class="ma-0 pa-0" v-if="company_value_loading || !values.length"> 
        <v-col cols="12" v-for="i in 3" :key="i" class="ma-auto" sm="8" md="4" data-aos="fade-down">
          <v-skeleton-loader
            type="image"
          ></v-skeleton-loader>
          <v-skeleton-loader class="my-2"
            type="heading"
          ></v-skeleton-loader>
          <v-skeleton-loader
            type="sentences,button"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row class="ma-0 pa-0" v-else> 
        <v-col cols="12" v-for="item in values" :key="item.id" class="ma-auto" sm="8" md="4" data-aos="fade-down">
          <v-card height="100%" class="values-cards transparent elevation-0" :dark="isDark">
            <v-card-title>
                <v-icon class=" text-h1 ma-auto" x-large>
                  {{item.icon}}
                </v-icon>
            </v-card-title>
            <v-card-title>
              <span v-line-clamp="1" class="ma-auto font-weight-light text-uppercase">{{$i18n.locale=='en'?item.titleEN:item.titleFR}}</span>
            </v-card-title>
            <v-card-title class="font-weight-light subtitle-2">
              <span v-line-clamp="2">{{ $i18n.locale=='en'?item.descriptionEN:item.descriptionFR}}</span>
            </v-card-title>
            <v-card-actions>
              <v-btn class="ma-auto" :dark="isDark" outlined rounded depressed @click="$navigateToPath(item.route)">
                {{$t("viewmore")}}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-row>
  </v-container>
</template>
<script>
import { mapState,mapActions,mapGetters} from "vuex";
export default {
  name: "WhatWeDo",
  props: ["isDark"],
  data() {
    return {
      company:null,
      values:[],
    };
  },
  components: {},
  async created(){
    await this.retrieveCompanies().then(()=>{
      this.company = this.companies[0];
    });
    await this.retrieveCompanyValues().then(()=>{
      this.values = this.company_values.filter(item=>{return item.company==this.companies[0].id && item.type=='whatwedo'});
    });
  },
  computed: {
    ...mapGetters('company', {
      companies:'getCompanies'
    }),
    ...mapState("company", {
      company_loading: "loading"
    }),
    ...mapGetters('company_value', {
      company_values:'getCompanyValues'
    }),
    ...mapState("company_value", {
      company_value_loading: "loading"
    }),
  },
  watch: {},
  methods: {

    ...mapActions("company", ["addCompany","retrieveCompanies"]),
    ...mapActions("company_value", ["addCompanyValue","retrieveCompanyValues"]),
  },
};
</script>
 
